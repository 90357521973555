import Footer from "./Footer";
import Header from "./Header";

function ContactForm() {
  return (
    <>
      <Header />
      <section className="w-full bgImageAboutUs" id="contactUs">
        <div className="max-w-[1440px] p-12 mx-auto h-full">
          <form className="text-center bg-white float-end w-[444px] p-12 rounded-lg">
            <h1 className="text-3xl font-bold uppercase text-primary">
              Contact Us
            </h1>
            <h3 className="my-4 text-xl font-semibold text-grayC-200">
              Please fill a form below
            </h3>
            <div className="flex flex-col w-full gap-4 text-left">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full p-4 rounded-md outline-none bg-grayC-75"
              />
              <input
                type="email"
                placeholder="Your Email"
                className="w-full p-4 rounded-md outline-none bg-grayC-75"
              />
              <textarea
                placeholder="Message.."
                className="w-full h-40 p-4 rounded-md outline-none resize-none bg-grayC-75 "
              />
              <button className="w-full p-4 text-white rounded-md bg-primary">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ContactForm;
