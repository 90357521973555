import React, { useState } from "react";
import IMAGES from "../images";
import Footer from "../components/Footer";
import Header from "../components/Header";

const TrackingPage = () => {
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const trackingIdPattern = /^[A-Z]{3}\d{6}[HMK]\d{5}$/;
  const hHouseBlPattern = /^[A-Z]{3}-\d{8}$/;
  const mMasterBlPattern = /^\d{3}-\d{8}\/\d{1}$/;

  function dataValidate(event) {
    setInputValue(event.target.value);
  }

  const fetchData = async (input) => {
    let headers = {};

   /*  if (trackingIdPattern.test(input)) {
     
    } else if (hHouseBlPattern.test(input)) {
      headers["TYPE"] = "H";
    } else if (mMasterBlPattern.test(input)) {
      headers["TYPE"] = "M";
    } else {
      console.error("Invalid Input");
    } */
    headers["TYPE"] = "I";
    headers["VALUE"] = input;

    try {
      setError(null);
      const response = await fetch(
        "https://axiacloud.axiaerp.com:8443/ords/webtrack01/track/track-job-stages/",
        {
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setData(data?.items);
      data?.items.length === 0 && setError("No data found");
    } catch (error) {
      setError("Error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  return (
    <>
      <Header />
      <section className="bgImageTracking ">
        <div className="flex flex-col lg:flex-row max-w-[1440px] mx-auto">
          <div className="flex flex-col w-full px-8 mt-20 lg:mt-0 lg:w-1/2">
            <div className="flex flex-row p-2 mt-8 bg-white border-2 border-solid rounded-xl border-primary lg:border-none lg:bg-transparent lg:shadow-none shadow-tracklineShadow max-w-[600px] mx-auto min-w-full lg:min-w-[500px] xl:min-w-[700px]">
              <input
                className=" text-xs xl:text-sm text-[#808080] font-medium outline-none w-full mx-2 lg:bg-white lg:border-2 lg:border-solid lg:rounded-lg lg:border-primary lg:p-2 lg:shadow-tracklineShadow"
                type="text"
                placeholder="Enter your tracking number"
                onChange={dataValidate}
                value={inputValue}
              />
              <button
                className="flex flex-row items-center gap-2 p-2 px-4 pr-8 text-xs font-semibold text-white rounded bg-primary lg:shadow-tracklineShadow"
                onClick={() => {
                  fetchData(inputValue);
                }}
              >
                <p>Track</p>
                <img src={IMAGES.locationTrack} alt="location" />
              </button>
            </div>
            {data.length > 0 && (
              <div className="p-8 mx-auto my-8 bg-white rounded-2xl max-w-[400px] shadow-faqShadow">
                <div className="">
                  <div className="flex items-center gap-7">
                    <div
                      className={`flex items-center justify-center w-8 h-8 ${data[0]?.status === "PENDING"
                          ? "border-primary"
                          : data[0]?.status === "COMPLETED"
                            ? "bg-green-600 border-green-600"
                            : "border-grayC-50"
                        } border-solid  border-4 rounded-full shadow-timelineShadow`}
                    >
                      {data[0]?.status === "PENDING" ? (
                        <img
                          src={IMAGES.pendingStatus}
                          alt="pending"
                          width={10}
                          height={10}
                        />
                      ) : data[0]?.status === "COMPLETED" ? (
                        <img
                          src={IMAGES.deliveredIcon}
                          alt="delivered"
                          width={20}
                          height={20}
                        />
                      ) : (
                        <img
                          src={IMAGES.blankIcon}
                          alt="none"
                          width={10}
                          height={10}
                        />
                      )}
                    </div>
                    <p
                      className={`text-sm font-medium ${data[0]?.status === "PENDING"
                          ? "text-primary"
                          : data[0]?.status === "COMPLETED"
                            ? "text-green-600"
                            : "text-black"
                        }  xl:text-xl`}
                    >
                      {data[0]?.event_text}
                    </p>
                  </div>

                  <div className="flex gap-11">
                    <div
                      className={`w-[17px] min-h-full border-r-2 ${data[0]?.status === "COMPLETED" &&
                          data[1]?.status === "COMPLETED"
                          ? "border-green-600"
                          : data[0]?.status === "COMPLETED" &&
                            data[1]?.status === "PENDING"
                            ? "border-primary"
                            : "border-grayC-50"
                        } border-solid`}
                    ></div>
                    <div className="mb-8">
                      <p className="text-[#929292] text-sm font-medium mt-2 italic">
                        {formatDate(data[0]?.event_date)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="flex items-center gap-7">
                    <div
                      className={`flex items-center justify-center w-8 h-8 border-4 border-solid rounded-full ${data[1]?.status === "COMPLETED"
                          ? "border-green-600 bg-green-600"
                          : data[1]?.status === "PENDING" &&
                            data[0]?.status === "PENDING"
                            ? "border-grayC-50"
                            : data[0]?.status === "COMPLETED" &&
                              data[1]?.status === "PENDING"
                              ? "border-primary"
                              : "border-grayC-50"
                        } shadow-timelineShadow`}
                    >
                      {data[1]?.status === "COMPLETED" ? (
                        <img
                          src={IMAGES.deliveredIcon}
                          alt="delivered"
                          width={20}
                          height={20}
                        />
                      ) : data[0]?.status === "COMPLETED" &&
                        data[1]?.status === "PENDING" ? (
                        <img
                          src={IMAGES.pendingStatus}
                          alt="pending"
                          width={10}
                          height={10}
                        />
                      ) : (
                        <img
                          src={IMAGES.blankIcon}
                          alt="blank"
                          width={10}
                          height={10}
                        />
                      )}
                    </div>
                    <p
                      className={`text-sm font-medium xl:text-xl ${data[0]?.status === "PENDING" &&
                          data[1]?.status === "PENDING"
                          ? "text-black"
                          : data[0]?.status === "COMPLETED" &&
                            data[1]?.status === "PENDING"
                            ? "text-primary"
                            : "text-green-600"
                        }`}
                    >
                      {data[1]?.event_text}
                    </p>
                  </div>

                  <div className="flex gap-11">
                    <div
                      className={`w-[17px] min-h-full border-r-2 ${data[1]?.status === "COMPLETED" &&
                          data[2]?.status === "COMPLETED"
                          ? "border-green-600"
                          : data[1]?.status === "COMPLETED" &&
                            data[2]?.status === "PENDING"
                            ? "border-primary"
                            : "border-grayC-50"
                        } border-solid`}
                    ></div>
                    <div className="mb-8">
                      <p className="text-[#929292] text-sm font-medium mt-2 italic">
                        {formatDate(data[1]?.event_date)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="flex items-center gap-7">
                    <div
                      className={`flex items-center justify-center w-8 h-8 border-4 border-solid rounded-full ${data[1]?.status === "COMPLETED" &&
                          data[2]?.status === "COMPLETED"
                          ? "border-green-600 bg-green-600"
                          : data[1]?.status === "COMPLETED" &&
                            data[2]?.status === "PENDING"
                            ? "border-primary"
                            : "border-grayC-50"
                        } shadow-timelineShadow`}
                    >
                      {data[2]?.status === "COMPLETED" ? (
                        <img
                          src={IMAGES.deliveredIcon}
                          alt="delivered"
                          width={20}
                          height={20}
                        />
                      ) : data[1]?.status === "COMPLETED" &&
                        data[2]?.status === "PENDING" ? (
                        <img
                          src={IMAGES.pendingStatus}
                          alt="pending"
                          width={10}
                          height={10}
                        />
                      ) : (
                        <img
                          src={IMAGES.blankIcon}
                          alt="none"
                          width={10}
                          height={10}
                        />
                      )}
                    </div>
                    <p
                      className={`text-sm font-medium ${data[1]?.status === "COMPLETED" &&
                          data[2]?.status === "COMPLETED"
                          ? "text-green-600"
                          : data[1]?.status === "COMPLETED" &&
                            data[2]?.status === "PENDING"
                            ? "text-primary"
                            : "text-black"
                        } xl:text-xl`}
                    >
                      {data[2]?.event_text}
                    </p>
                  </div>

                  <div className="flex gap-11">
                    <div className="w-4 min-h-full"></div>
                    <div className="mb-6">
                      <p className="text-[#929292] text-sm font-medium mt-2 italic">
                        {formatDate(data[2]?.event_date)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {
              error && <p className="text-center text-red-500 mt-3 text-2xl">{error}</p>
            }
          </div>
          <div className="w-full px-8 my-12 lg:mt-28 lg:w-1/2">
            <img src={IMAGES.trackingTruck} alt="Trucks" className="mx-auto" />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TrackingPage;
