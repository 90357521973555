import { useEffect, useRef, useState } from "react";
import IMAGES from "../images";
import { Link } from "react-router-dom";

const Header = () => {
  const [showNav, setShowNav] = useState(false);
  const dropdownRef = useRef(null);
  const hamburgerRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !hamburgerRef.current.contains(event.target)
      ) {
        console.log("useffect called");
        setShowNav(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className="hidden lg:block lg:w-full poppin 2xl:pl-20 shadow-faqShadow">
        <div className="flex flex-row justify-between gap-20 mx-16 2xl:gap-40">
          <img src={IMAGES.Logo} alt="logo" />

          <div className="flex flex-col w-full">
            <div className="flex flex-row justify-end gap-6 my-2 mr-2 xl:gap-12">
              <a href="https://www.google.com/maps/search/Wakra+Boulevard,+Office+No-+133,+Abdul+Rahman+Bin+Jassim+Street,+P.O+Box:+63434,+++Al+Wakrah,+State+Of+Qatar/@33.5528314,6.131364,4z/data=!3m1!4b1?entry=ttu">
                <div className="flex flex-row gap-2">
                  <div className="flex items-center justify-center border-2 border-solid rounded-full w-9 h-9 border-primary">
                    <img src={IMAGES.homeIcon} alt="homeIcon" />
                  </div>
                  <div className="flex flex-col justify-center text-xs">
                    <p className="">Address</p>
                    <p className="text-grayC-125">Al Wakrah, State Of Qatar</p>
                  </div>
                </div>
              </a>
              <a href="tel:(+974) 30002226">
                <div className="flex flex-row gap-2">
                  <div className="flex items-center justify-center border-2 border-solid rounded-full w-9 h-9 border-primary">
                    <img src={IMAGES.phoneIcon} alt="homeIcon" />
                  </div>
                  <div className="flex flex-col justify-center text-xs">
                    <p className="">Phone Number</p>
                    <p className="text-grayC-125">(+974) 30002226</p>
                  </div>
                </div>
              </a>
              <a href={`mailto:${"connect@scorpionlogistics.qa"}`}>
                <div className="flex flex-row gap-2">
                  <div className="flex items-center justify-center border-2 border-solid rounded-full w-9 h-9 border-primary">
                    <img src={IMAGES.mailIcon} alt="homeIcon" />
                  </div>

                  <div className="flex flex-col justify-center text-xs">
                    <p className="">Email ID</p>
                    <p className="text-grayC-125">
                      connect@scorpionlogistics.qa
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="flex flex-row justify-between text-lg text-white bg-blueBg rounded-t-lg">
              <nav className="flex gap-8 mx-12 my-3 2xl:mx-20 xl:gap-12">
                <Link to="/">
                  <p className="hover:text-primary">Home</p>
                </Link>
                <Link to="/services">
                  <p className="hover:text-primary">Services</p>
                </Link>
                <Link to="/about">
                  <p className="hover:text-primary">About us</p>
                </Link>

                <Link to="/contact">
                  <p className="hover:text-primary">Contact us</p>
                </Link>
              </nav>
              <Link to="/tracking">
                <button className="px-8 py-3 linearOrange hover:text-black">
                  Track Here
                </button>
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div className="fixed top-0 z-20 w-full py-4 bg-white shadow-xl top lg:hidden poppin">
        <div className="flex flex-row items-center justify-between mx-8">
          <img src={IMAGES.Logo} alt="logo" />
          <div className="relative">
            <img
              ref={hamburgerRef}
              src={IMAGES.menuIcon}
              alt="menu"
              className="w-8 h-8 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setShowNav((prev) => !prev);
              }}
            />
            {showNav && (
              <div className="absolute top-[53px] w-[99999%] h-[99999%] -right-8 bg-slate-100/60 flex justify-end flex-row z-10">
                <div
                  className=" min-h-screen w-[200px] bg-blueBg text-white"
                  ref={dropdownRef}
                >
                  <nav className="flex flex-col ">
                    <Link to="/">
                      <p
                        className="w-full p-4 hover:bg-primary focus:bg-primary "
                        onClick={() => setShowNav(!showNav)}
                      >
                        Home
                      </p>
                    </Link>
                    <Link to="/services">
                      <p
                        className="w-full p-4 hover:bg-primary focus:bg-primary "
                        onClick={() => setShowNav(!showNav)}
                      >
                        Services
                      </p>
                    </Link>
                    <Link to="/about">
                      <p
                        className="w-full p-4 hover:bg-primary focus:bg-primary "
                        onClick={() => setShowNav(!showNav)}
                      >
                        About us
                      </p>
                    </Link>

                    <Link to="/about">
                      <p
                        className="w-full p-4 hover:bg-primary focus:bg-primary "
                        onClick={() => setShowNav(!showNav)}
                      >
                        Contact us
                      </p>
                    </Link>
                    <Link to="/tracking">
                      <button className="w-full py-3 linearOrange hover:text-black">
                        Track Here
                      </button>
                    </Link>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
