import Footer from "../components/Footer";
import Header from "../components/Header";
import IMAGES from "../images";
import Button from "../components/Button";
import KnowledgeCenter from "../components/KnowledgeCenter";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <Header />
      <section className="w-full pt-24 lg:pb-20 2xl:py-40 md:mb-28 bgImageHome">
        <div className="w-full lg:max-w-[1440px] mx-auto flex flex-row lg:px-20 my-8 ">
          <div className="pl-4 pr-12 lg:w-4/6">
            <h3 className="text-xl font-semibold lg:text-3xl text-primary leading-42">
              WELCOME TO
            </h3>
            <h1 className="font-bold text-[30px] lg:text-[50px] text-grayC-400 lg:text-white">
              SCORPION LOGITICS
            </h1>
            <p className="mb-4 font-medium text-primary text-18">
              The Value-Add In Logistics
            </p>
            <p className="mb-6 font-normal text-20 leading-30 text-grayC-100 lg:text-white">
              Scorpion Logistics, a Qatar based company with logistics experts
              provides Shipping and Logistics solutions throughout the world.
            </p>
            <Link to="/about">
              <Button text={"READ MORE"} />
            </Link>
          </div>
        </div>
        <div className="w-full mb-12 lg:hidden">
          <img src={IMAGES.MobileHomeBg} alt="" className="w-full" />
        </div>
      </section>

      <section className="w-full">
        <div className="max-w-[1440px] mx-auto mb-4 lg:mb-20">
          <h3 className="text-28 lg:text-36 md:leading-[54px] text-grayC-300 font-bold text-center mb-4 lg:my-12">
            DELIVERING THE BEST GLOBAL LOGISTIC SOLUTIONS
          </h3>
          <div className="flex flex-col items-center justify-center gap-8 mx-10 lg:gap-10 lg:flex-row">
            <div className="flex flex-col items-center justify-center text-center w-72">
              <figure className="bg-[#E9F8FF] rounded-xl p-[18px]">
                <img src={IMAGES.BoatIcon} alt="boatIcon" />
              </figure>
              <p className="mt-6 mb-4 font-bold text-18 text-grayC-200">
                SECTOR EXPERTISE
              </p>
              <p className="font-normal text-14 leading-21 text-grayC-150">
              The management of goods and services from their point of origin to their final destination.
              </p>
            </div>
            <div className="hidden h-40 border-r-2 border-solid lg:block border-grayC-50"></div>
            <div className="flex flex-col items-center justify-center text-center w-72">
              <figure className=" bg-[#EEFCF5] rounded-xl p-[18px]">
                <img src={IMAGES.BoxIcon} alt="Box" />
              </figure>
              <p className="mt-6 mb-4 font-bold text-18 text-grayC-200">
                PRODUCTS AND SOLUTIONS
              </p>
              <p className="font-normal text-14 leading-21 text-grayC-150">
              It encompass a wide range of offerings tailored to streamline operations and enhance efficiency throughout the supply chain.
              </p>
            </div>
            <div className="hidden h-40 border-r-2 border-solid lg:block border-grayC-50"></div>
            <div className="flex flex-col items-center justify-center text-center w-72">
              {" "}
              <figure className=" bg-[#FFF5EF] rounded-xl p-[18px]">
                <img src={IMAGES.StarIcon} alt="star" />
              </figure>
              <p className="mt-6 mb-4 font-bold text-18 text-grayC-200">
                PRODUCTS AND SOLUTIONS
              </p>
              <p className="font-normal text-14 leading-21 text-grayC-150">
              It encompass a wide range of offerings tailored to streamline operations and enhance efficiency throughout the supply chain.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="hidden w-full lg:block">
        <div className="max-w-[1440px] mx-auto mb-20  flex flex-row px-20 gap-16 xl:justify-center xl:gap-0">
          <div className="w-1/2 xl:ml-20 ">
            <div className="relative w-[456px] h-[493px] bg-primary rounded-t-full">
              <img
                className=" absolute w-[434px] h-[517px] right-0 top-6"
                src={IMAGES.valueIcon}
                alt="Our Value"
              />
            </div>
          </div>
          <div className="w-1/2 mt-20">
            <p className="text-[42px] font-semibold text-grayC-300">
              OUR VALUES
            </p>
            <p className="text-xl font-medium mt-9 text-grayC-200">
              Providing quality service and customized solutions resulting in
              increased productivity.
            </p>
            <p className="mt-4 font-normal text-md text-grayC-100">
              Scorpion Logistics, a Qatar based company with logistics experts
              provides Shipping and Logistics solutions throughout the world.
              Scorpion Logistics provides door to door delivery solutions with
              our in-house warehousing and distribution facilities.
            </p>
            <p className="my-4 text-md text-grayC-100">
              Scorpion logistics is capable of handling Air/Ocean imports and
              exports from all corners of the world. Sea freight services
              include both Full container load and Less than container load
              shipment worldwide.
            </p>
            <Button text={"READ MORE"} />
          </div>
        </div>
      </section>

      <section className="w-full">
        <div className="max-w-[1440px] mx-auto mb-4 lg:mb-20">
          <h3 className="text-28 lg:text-36 leading-[54px] text-grayC-300 font-bold text-center mt-8 mb-4 lg:my-12 xl:mt-24">
            KNOWLEDGE CENTER
          </h3>
          <div className="grid grid-cols-1 gap-4 place-items-center lg:grid-cols-2 lg:gap-8 lg:px-32">
            <KnowledgeCenter
              imageSrc={IMAGES.codeIcon}
              subHead={"HS CODE"}
              description={
                "The Harmonized System (HS) Code is an internationally recognized classification system used to categorize goods traded across borders"
              }
            />
            <KnowledgeCenter
              imageSrc={IMAGES.qatarIcon}
              subHead={"QATAR CUSTOMS"}
              description={
                "Qatar Customs is the government agency responsible for regulating and facilitating international trade and customs procedures in the State of Qatar."
              }
            />
            <KnowledgeCenter
              imageSrc={IMAGES.containerIcon}
              subHead={"CONTAINER GUIDE"}
              description={
                "It is a comprehensive document or resource that provides detailed information on the specifications, regulations, and best practices related to shipping containers."
              }
            />
            <KnowledgeCenter
              imageSrc={IMAGES.uldIcon}
              subHead={"ULD GUIDE"}
              description={
                "ULD (Unit Load Device) guide is a comprehensive resource providing detailed information on the specifications, handling procedures, and regulations related to air cargo containers"
              }
            />
            <KnowledgeCenter
              imageSrc={IMAGES.trailerIcon}
              subHead={"TRAILER GUIDE"}
              description={
                "It is a reference document or resource that offers comprehensive information and guidance related to trailers used in transportation and logistics."
              }
            />
            <KnowledgeCenter
              imageSrc={IMAGES.logisticsIcon}
              subHead={"LOGISTICS TERMINOLOGY"}
              description={
                "Logistics terminology encompasses a wide range of specialized terms and concepts used in the field of logistics and supply chain management."
              }
            />
          </div>
        </div>
      </section>
      <section className="w-full">
        <div className="max-w-[1440px] mx-auto mb-8 lg:mb-20">
          <h3 className=" text-28 lg:text-36 leading-[54px] text-grayC-300 font-bold text-center mb-4 lg:my-12">
            OUR SPECIALITIES
          </h3>
          <div className="flex flex-col justify-center gap-4 mx-10 lg:gap-16 lg:flex-row place-items-center ">
            <div className="relative flex flex-col items-center justify-center text-center w-72">
              <img src={IMAGES.supportIcon} />
              <p className="absolute z-10 top-[46%] font-semibold text-white text-20 uppercase">
                Support
              </p>
            </div>
            <div className="relative flex flex-col items-center justify-center text-center w-72">
              <img src={IMAGES.shippingIcon} />
              <p className="absolute z-10 top-[40%] font-semibold text-white text-20 uppercase">
                SHIPPING
                <br /> INSTRUCTION
              </p>
            </div>
            <div className="relative flex flex-col items-center justify-center text-center w-72">
              <img src={IMAGES.corporateIcon} />
              <p className="absolute z-10 top-[40%] font-semibold text-white text-20 uppercase">
                CORPORATE
                <br />
                RESPONSIBITY
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full">
        <div className="max-w-[1440px] mx-auto mb-8 lg:mb-20">
          <div className="flex flex-row justify-between gap-4 px-4 md:justify-center md:px-20 lg:px-32 lg:gap-12">
            <div className="w-1/2 gap-4 my-auto lg:w-2/3">
              <p className="mb-0.5 text-lg font-bold lg:mb-8 md:text-2xl lg:text-4xl text-grayC-300">
                OUR VISION
              </p>
              <p className="text-xs md:text-lg text-grayC-100 lg:leading-30">
                Our operations are smoothened out with the help of our
                Associates who are strategically located across the world. They
                are essentially handpicked professional organisations linked up
                to form a highly response-led and effective.
              </p>
            </div>
            <div className="w-1/2 lg:w-1/3">
              <img className="flex-1" src={IMAGES.visionIcon} />
            </div>
          </div>
          <div className="flex flex-row justify-between gap-4 px-4 md:justify-center md:px-24 lg:px-32 lg:gap-12">
            <div className="w-1/2 lg:w-1/3">
              <img className="flex-1" src={IMAGES.missionIcon} />
            </div>
            <div className="w-1/2 my-auto lg:w-2/3">
              <p className="mb-0.5 text-lg font-bold lg:mb-8 md:text-2xl lg:text-4xl text-grayC-300">
                OUR MISSION
              </p>
              <p className="text-xs md:text-lg text-grayC-100 lg:leading-30">
                Scorpion Logistics is well connected globally to serve the
                customer by giving real-time information on the product as it
                travels through the supply chain. Your information security is
                our prime concern.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full">
        <div className="max-w-[1440px] mx-auto">
          <div className="mx-auto max-w-fit">
            <h3 className=" text-28 text-center lg:text-left lg:mx-20 xl:mx-0 lg:text-[42px] font-semibold text-grayC-300 mb-8">
              OUR GALLERY
            </h3>
            <div className="flex flex-row px-2">
              <div className="grid grid-cols-2 grid-rows-2 gap-2 lg:gap-8">
                <img src={IMAGES.grid1Icon} />
                <img src={IMAGES.grid2Icon} />
                <img src={IMAGES.grid4Icon} className="grid col-span-2" />
              </div>
              <div className="flex flex-col gap-2 ml-2 lg:ml-8 lg:gap-8">
                <img src={IMAGES.grid3Icon} />
                <img src={IMAGES.grid5Icon} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full">
        <div className="lg:max-w-[1440px] mx-auto mb-20">
          <div className="w-72  md:w-[460px] lg:w-[800px]  mx-auto">
            <h3 className=" text-20 md:text-2xl lg:text-[40px] text-black font-semibold text-center">
              GET IN TOUCH WITH US
            </h3>
            <p className="my-2 text-sm text-center md:my-8 lg:mx-24 lg:text-lg text-grayC-100">
              Get in touch with us to start the conversation about your logistics
            </p>
            <div className="flex flex-row justify-between">
              <input
                type="email"
                placeholder="Enter your email"
                className="p-2 lg:p-6 w-[72%] border text-sm lg:text-lg rounded-md border-solid border-grayC-100 placeholder:text-grayC-100"
              />
              <button
                type="submit"
                className="font-medium lg:font-semibold p-2 lg:p-6 w-[27%] lg:w-[25%] border rounded-md text-sm lg:text-xl border-primary border-solid text-white bg-primary"
              >
                SEND
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Home;
