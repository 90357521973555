import Footer from "../components/Footer";
import Header from "../components/Header";
import IMAGES from "../images";

function About() {
  return (
    <>
      <Header />
      <section className="flex items-center justify-center mt-16 text-3xl font-bold text-white md:text-4xl lg:text-5xl bgCotainerImageAbout lg:mt-0">
        About Us
      </section>
      <section className="w-full">
        <div className="max-w-[1440px] mx-auto xl:mb-20 lg:mt-20">
          <div className="flex flex-col justify-between lg:mx-16 lg:flex-row">
            <div className="px-4 my-4 lg:px-0 lg:my-0 lg:mr-0 lg:w-1/2 lg:pb-12 xl:mr-8">
              <div className="font-semibold max-w-fit lg:leading-[45px] text-lg xl:text-3xl ">
                <h3 className="text-xl font-extrabold lg:text-4xl xl:text-5xl text-primary openSans">
                  Why Choose Us?
                </h3>
                <div className="my-2 text-lg font-semibold lg:text-xl xl:text-2xl text-grayC-100 openSans">
                  We Bridge Distances, We Deliver Dreams
                </div>
              </div>
              <p className="my-3 text-grayC-100 xl:leading-30">
                Scorpion Logistics, a Qatar based company with logistics experts
                provides Shipping and Logistics solutions throughout the world.
                Scorpion Logistics provides door to door delivery solutions with
                our in-house warehousing and distribution facilities.
              </p>
              <p className="text-grayC-100 xl:leading-30">
                Scorpion logistics is capable of handling Air/Ocean imports and
                exports from all corners of the world. Sea freight services
                include both Full container load and Less than container load
                shipment worldwide.
              </p>
            </div>
            <div className="lg:w-1/2 max-w-fit md:w-[170%] sm:mx-auto lg:mx-0 lg:mt-8 xl:mt-0">
              <img
                src={IMAGES.aboutOne}
                width={664}
                height={484}
                className="md:w-[120%]"
              />
            </div>
          </div>
          <div className="bg-primary w-[75%] mx-auto relative -top-12 lg:left-8 rounded-md pt-6 lg:p-8 text-white pb-4">
            <div className="flex flex-row justify-around gap-4 overflow-x-auto xl:ml-8">
              <div className="flex flex-col items-center gap-4 mx-4 text-center ml-60 sm:ml-12">
                <img
                  src={IMAGES.truck}
                  width={58}
                  height={58}
                  className="w-8 h-8"
                />
                <p className="text-xs">Freight</p>
              </div>
              <div className="flex flex-col items-center gap-4 mx-2 text-center">
                <img
                  src={IMAGES.airoplan}
                  width={52}
                  height={52}
                  className="w-8 h-8"
                />
                <p className="text-xs">
                  Customs
                  <br />
                  Clearance
                </p>
              </div>

              <div className="flex flex-col items-center gap-4 mx-2 text-center">
                <img
                  src={IMAGES.notesBook}
                  width={39}
                  height={42}
                  className="w-8 h-8"
                />
                <p className="text-xs">
                  Warehousing and <br />
                  distribution
                </p>
              </div>
              <div className="flex flex-col items-center gap-4 mx-2 text-center">
                <img
                  src={IMAGES.code}
                  width={48}
                  height={41}
                  className="w-8 h-8"
                />
                <p className="text-xs">
                  Documents <br />
                  consultancy
                </p>
              </div>
              <div className="flex flex-col items-center gap-4 mx-2 mr-8 text-center">
                <img
                  src={IMAGES.location}
                  width={36}
                  height={42}
                  className="w-8 h-8"
                />
                <p className="text-xs">
                  Events and <br /> exhibition
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center gap-2 mt-4 lg:gap-8">
              <div className="border-b-2 border-white border-solid w-16 sm:w-[36%]"></div>
              <button className="px-4 py-2 text-xs bg-white rounded-lg lg:text-lg text-primary hover:opacity-80 ">
                Learn More
              </button>
              <div className="border-b-2 border-white border-solid w-16 sm:w-[36%]"></div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="w-full bgImageAboutUs openSans  max-h-[500px] lg:min-h-[640px]"
        id="contactUs"
      >
        <div className="max-w-[1440px] pt-16 px-8 md:p-12 mx-auto h-full">
          <form className="text-center bg-white md:float-end lg:w-[444px] md:w-[344px] p-4 lg:p-12 rounded-lg">
            <h1 className="text-2xl font-bold uppercase lg:text-3xl text-primary">
              Contact Us
            </h1>
            <h3 className="my-4 text-lg font-semibold lg:text-xl text-grayC-200">
              Please fill a form below
            </h3>
            <div className="flex flex-col w-full gap-4 text-left">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full p-2 px-4 text-xs rounded-md outline-none lg:p-4 lg:text-md bg-grayC-75"
              />
              <input
                type="email"
                placeholder="Your Email"
                className="w-full p-2 px-4 text-xs rounded-md outline-none lg:p-4 bg-grayC-75 lg:text-md"
              />
              <textarea
                placeholder="Message.."
                className="w-full h-20 p-2 px-4 text-xs rounded-md outline-none resize-none lg:p-4 md:h-32 lg:h-40 bg-grayC-75 lg:text-md"
              />
              <button className="w-full p-2 px-4 text-xs text-white rounded-md lg:p-4 bg-primary lg:text-md">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default About;
